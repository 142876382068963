import "styles/pages/page-psycho-tests.scss";

import React, { useState } from "react";
import { graphql, Link, withPrefix } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

import Breadcrumps from "components/Breadcrumps";
import SubpageHeader from "components/SubpageHeader";

const PsychoTests = ({ data }) => {
  const [tab, setTab] = useState(0);
  const content = data?.allWpPage?.nodes[0]?.acfTestyPsychologiczne?.data;

  return (
    <Layout>
      <Seo
        title="Testy psychologiczne"
        image={withPrefix("/og-image-psycho-tests.jpg")}
      />
      <Breadcrumps data="Testy psychologiczne" />
      <SubpageHeader title="Testy psychologiczne" />

      <section className="psycho-tests">
        <div className="container">
          <div
            className="psycho-tests__top-desc"
            dangerouslySetInnerHTML={{
              __html:
                data?.allWpPage?.nodes[0]?.acfTestyPsychologiczne
                  ?.descriptionTop,
            }}
          />
          <div className="psycho-tests__nav">
            {content?.map((item, index) => (
              <button
                className={tab === index ? "current" : ""}
                onClick={() => setTab(index)}
                key={index}
              >
                {item.contentHeader}
              </button>
            ))}
          </div>
          {content
            .filter((item, index) => index === tab)
            .map((item, index) => (
              <div className="psycho-tests__content" key={index}>
                <h3>{item.contentSubheader}</h3>
                <div className="row">
                  {item?.contentImage?.localFile?.publicURL && (
                    <div className="col-md-6 text-center">
                      <img
                        className="img-fluid"
                        src={item?.contentImage?.localFile?.publicURL}
                        alt=""
                      />
                    </div>
                  )}
                  {item?.contentTiles?.map((item, dex) => (
                    <div className="col-xl-3 col-md-6" key={dex}>
                      <div className="psycho-tests__tile">
                        <div>
                          {/* <span>Świadczenie</span> */}
                          <h4>{item.swiadczenie}</h4>
                        </div>

                        <div>
                          {item.stacjonarnieOnline && (
                            <>
                              <span>Stacjonarnie/Online</span>
                              <p>{item.stacjonarnieOnline}</p>
                            </>
                          )}
                          {item.czas && (
                            <>
                              <span>Czas</span>
                              <p>{item.czas}</p>
                            </>
                          )}
                          {item.wiek && (
                            <>
                              <span>Wiek</span>
                              <p>{item.wiek}</p>
                            </>
                          )}
                          {item.link && (
                            <Link
                              to={item.link}
                              className="button button--small button--green"
                            >
                              Dowiedz się więcej
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          <div
            className="psycho-tests__bottom-desc"
            dangerouslySetInnerHTML={{
              __html:
                data?.allWpPage?.nodes[0]?.acfTestyPsychologiczne
                  ?.descriptionBottom,
            }}
          />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMDQ3MQ==" } }) {
      nodes {
        acfTestyPsychologiczne {
          descriptionTop
          descriptionBottom
          data {
            contentHeader
            contentSubheader
            contentImage {
              localFile {
                publicURL
              }
            }
            contentTiles {
              swiadczenie
              stacjonarnieOnline
              czas
              wiek
              link
            }
          }
        }
      }
    }
  }
`;

export default PsychoTests;
